// import { URLs } from '@common/api';
import { getData, URLs } from '@common/api';
import * as UserSection from '@common/models/UserSection';
import { fetchCategoryListSaga } from '@common/store/categoryList/sagas';
import { fetchSliderSaga } from '@common/store/heroBanner/sagas';
import { fetchHtmlBlockSaga } from '@common/store/htmlBlock/sagas';
import { fetchImageAndTextSaga } from '@common/store/imageAndText/sagas';
import { fetchPageHeaderSaga } from '@common/store/pageHeader/sagas';
import { fetchProductListSaga } from '@common/store/productList/sagas';
import { fetchRichTextEditorSaga } from '@common/store/richTextEditor/sagas';
import { fetchTextSaga } from '@common/store/text/sagas';
import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, ForkEffect, put, select, takeEvery } from 'redux-saga/effects';

import { isBrowser } from './../../utils/index';
// import { fetchCacheSuccess } from '../cache/slice';
import {
  fetchMoreUserSectionsRequest,
  fetchMoreUserSectionsSuccess,
  fetchUserSectionsFailure,
  fetchUserSectionsRequest,
  FetchUserSectionsRequestPayloadType,
  fetchUserSectionsSuccess,
  updateCount,
  updateCurrentPage,
} from './slice';

const urlParams = new URLSearchParams(isBrowser() && window.location.search);
const isMerchant = urlParams.get('merchant');

const NUMBER_OF_SECTIONS_PER_REQUEST = isMerchant ? 500 : 6;

function* fetchUserSectionsSaga({
  payload: { page = 'home', userId = null } = {},
}: PayloadAction<FetchUserSectionsRequestPayloadType>) {
  try {
    const data = yield call(() =>
      getData({
        url: `${URLs.GET_USER_SECTIONS.replace(
          '{storePageName}',
          page,
        )}?limit=${NUMBER_OF_SECTIONS_PER_REQUEST}&page=1&is_visible=true`,
      }),
    );

    const sections = data?.data?.results.map((section) => {
      return UserSection.processResponse(section);
    });
    const yields = sections
      .map((section) => {
        switch (section.sectionType) {
          case 'Page Header':
            return call(fetchPageHeaderSaga, { payload: section.id });
          case 'Rich Text Editor':
            return call(fetchRichTextEditorSaga, { payload: section.id });
          case 'Product Showcase':
            return call(fetchProductListSaga, { payload: { sectionId: section.id, userId } });
          case 'Category Showcase':
            return call(fetchCategoryListSaga, { payload: { sectionId: section.id, userId } });
          case 'Hero Banner':
            return call(fetchSliderSaga, { payload: section.id });
          case 'Image & Text':
            return call(fetchImageAndTextSaga, { payload: section.id });
          case 'Text':
            return call(fetchTextSaga, { payload: section.id });
          case 'HTML Block':
            return call(fetchHtmlBlockSaga, { payload: section.id });
        }
      })
      .filter((section) => section);
    yield all(yields);
    yield put(fetchUserSectionsSuccess({ sections, isMerchant }));
    yield put(updateCount(data.data.count));
  } catch (e) {
    yield put(fetchUserSectionsFailure('Request failed with: ' + e));
  }
}

function* fetchMoreUserSectionsSaga({ payload = 'home' }) {
  try {
    const userSections = yield select((store) => {
      return store.userSections;
    });
    const { currentPage, count } = userSections;
    const { length } = userSections.userSections;
    if (length && length < count) {
      const data = yield call(() =>
        getData({
          url: `${URLs.GET_USER_SECTIONS.replace(
            '{storePageName}',
            payload,
          )}?limit=${NUMBER_OF_SECTIONS_PER_REQUEST}&page=${isMerchant ? 1 : currentPage + 1}&is_visible=true`,
        }),
      );
      const sections = data?.data?.results.map((section) => {
        return UserSection.processResponse(section);
      });
      yield put(fetchMoreUserSectionsSuccess({ sections, isMerchant }));
      yield put(updateCurrentPage(currentPage + 1));
    }
  } catch (e) {
    yield put(fetchUserSectionsFailure('Request failed with: ' + e));
  }
}

export default function* userSectionSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchUserSectionsRequest.type, fetchUserSectionsSaga);
  yield takeEvery(fetchMoreUserSectionsRequest.type, fetchMoreUserSectionsSaga);
}
