import UserSection from '@common/models/UserSection';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UserSectionsSliceType {
  userSections?: Array<UserSection>;
  isMerchant?: boolean;
  isFetching: boolean;
  isFetched: boolean;
  error: string;
  count: number;
  currentPage: number;
}
export interface FetchUserSectionsRequestPayloadType {
  userId?: number;
  page?: string;
}

const userSectionsSlice = createSlice({
  name: 'userSections',
  initialState: <UserSectionsSliceType>{
    userSections: [],
    isFetching: false,
    isFetched: false,
    error: '',
    count: 0,
    currentPage: 1,
  },
  reducers: {
    fetchUserSectionsRequest(state) {
      state.isFetching = true;
      state.isFetched = false;
      // state.userSections = null;
      state.error = '';
    },
    fetchMoreUserSectionsRequest(state) {
      state.isFetching = true;
      // state.isFetched = false;
      // state.userSections = null;
      state.error = '';
    },
    fetchUserSectionsSuccess(state, { payload }: PayloadAction<Array<UserSection>>) {
      const { sections, isMerchant } = payload;
      state.userSections = isMerchant ? sections : [...sections];
      state.isFetching = false;
      state.isFetched = true;
    },
    fetchMoreUserSectionsSuccess(state, { payload }: PayloadAction<Array<UserSection>>) {
      const { sections, isMerchant } = payload;
      state.userSections = isMerchant ? sections : [...state.userSections, ...sections];
      state.isFetching = false;
      state.isFetched = true;
    },
    fetchUserSectionsFailure(state, { payload }: PayloadAction<string>) {
      state.error = payload;
      state.isFetching = false;
      state.isFetched = true;
    },
    addSections(state, { payload }) {
      state.userSections = [...state.userSections, payload];
    },
    updateCount(state, { payload }) {
      state.count = payload;
    },
    updateCurrentPage(state, { payload }) {
      state.currentPage = payload;
    },
    deleteSection(state) {
      state.isFetching = true;
      state.isFetched = false;
      state.error = '';
    },
  },
});

export const {
  fetchUserSectionsSuccess,
  fetchMoreUserSectionsSuccess,
  fetchMoreUserSectionsRequest,
  fetchUserSectionsRequest,
  fetchUserSectionsFailure,
  addSections,
  updateCount,
  updateCurrentPage,
  deleteSection,
} = userSectionsSlice.actions;

export default userSectionsSlice.reducer;
